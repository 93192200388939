.root {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
    border-top: 1px solid lightgrey;
}

.root > button {
    margin-left: 10px;
}
