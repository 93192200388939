.root {
    background-color: #505762;
    color: white;
    font-weight: bold;
    padding: 6px 8px 6px 20px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
}

.title {
    margin-right: auto;
}

.closeButton {
    padding: 1px 8px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: white;
    border-radius: 4px;
}
