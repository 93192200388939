.root {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.root > div {
    width: 64px;
    height: 64px;
}
