@import '../../styles/index';

.root {
    z-index: 100;

    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
}

.window {
    padding: 0;
    background-color: white;
    border: 1px solid #505762;
    border-radius: 4px;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
